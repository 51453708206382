import { useState, useCallback, useEffect } from 'react'

import { useLazyQuery } from '@apollo/client'
import { Divider, Loader } from '@mantine/core'
import { useForm } from '@mantine/form'
import { zodResolver } from 'mantine-form-zod-resolver'
import { z } from 'zod'

import { Metadata, useMutation, useQuery } from '@redwoodjs/web'

import Button from 'src/components/Buttons/Button/Button'
import QuestionRow from 'src/components/Inputs/QuestionRow/QuestionRow'
import { PDFPreviewModal } from 'src/components/Overlays/PDFPreviewModal/PDFPreviewModal'
import { toast } from 'src/components/Overlays/Toast/Toast'
import { GET_DRIVER_CONSENT_URL_TO_DOWNLOAD } from 'src/graphql/consent.graphql'
import { VoEStatus } from 'src/graphql/types/verificationOfEmployments'
import { GET_VOE_BY_ID, MARK_VOE_AS_COMPLETED } from 'src/graphql/voe.graphql'
import IconAlertCircle from 'src/icons/IconAlertCircle'
import { downloadFile } from 'src/utils/downloadFile'

import AccidentsCard from '../ApplicantDetailsPage/tabs/ApplicationTab/components/AccidentsCard'
import AuditTrailCard from '../VoERequestDetailsPage/components/AuditTrailCard'
import CompanyInfoCard from '../VoERequestDetailsPage/components/CompanyInfoCard'
import DriverInfoCard from '../VoERequestDetailsPage/components/DriverInfoCard'
import RequestContentColumn from '../VoERequestDetailsPage/components/RequestContentColumn'
import RequestInfoCard from '../VoERequestDetailsPage/components/RequestInfoCard'
import ResponseContentColumn from '../VoERequestDetailsPage/components/ResponseContentColumn'
import VoEConsentCard from '../VoERequestDetailsPage/components/VoEConsentCard'
import VoEDetailsHeader from '../VoERequestDetailsPage/components/VoEDetailsHeader'

const onError = () => {
  toast('Something went wrong, please try again.', 'error')
}

const VoEResponseDetailPage = ({ voeId }) => {
  const [openConsent, setOpenConsent] = useState(false)
  const [driverConsentFileUrl, setDriverConsentFileUrl] = useState()

  const schema = z.object({
    positionHeld: z
      .string()
      .min(1, { message: 'This field is required' })
      .max(200, { message: '200 character max' }),
    startDate: z.date(),
    endDate: z.date().optional().nullable(),
    reasonForLeaving: z
      .string()
      .min(1, { message: 'This field is required' })
      .max(1000, { message: '1000 character max' }),
    eligibleForRehire: z.boolean(),
    isDriverTerminated: z.boolean(),
    isDriverSubjectToFMCSRs: z.boolean(),
    isJobDesignatedAsSafetySensitiveFunctionUnderDOT: z.boolean(),
    accidents: z.array(
      z.object({
        accidentDate: z.date().optional(),
        city: z.string().min(1).max(100),
        state: z.string().min(2).max(50),
        description: z.string().min(1).max(1000),
        isPreventable: z.boolean(),
        isCommercialMotorVehicleInvolved: z.boolean(),
      })
    ),
  })

  const form = useForm({
    validate: zodResolver(schema),
  })

  const [submitVoEResponse, { loading: loadingSubmit }] = useMutation(
    MARK_VOE_AS_COMPLETED,
    {
      refetchQueries: [
        {
          query: GET_VOE_BY_ID,
          variables: {
            id: voeId,
          },
        },
      ],
    }
  )

  const { data: voe, loading } = useQuery(GET_VOE_BY_ID, {
    variables: {
      id: voeId,
    },
    onCompleted: () => {
      handleInitialValues()
    },
    onError,
  })

  const [getDriverFileUrl, { loading: loadingPreviewConsent }] = useLazyQuery(
    GET_DRIVER_CONSENT_URL_TO_DOWNLOAD,
    {
      variables: {
        applicationId:
          voe?.verificationOfEmployment?.applicant?.application?.applicationId,
      },
      onCompleted: ({ getDriverConsentFileDownloadUrlByApplication: url }) => {
        setDriverConsentFileUrl(url)
        setOpenConsent(true)
      },
      onError,
    }
  )

  const handleSubmit = () => {
    submitVoEResponse({
      variables: {
        id: voeId,
      },
    })
  }

  const handleInitialValues = () => {
    form.setValues(
      voe?.verificationOfEmployment.status === VoEStatus.PENDING_REVIEW ||
        voe?.verificationOfEmployment.status === VoEStatus.COMPLETED
        ? voe.verificationOfEmployment.responseContent
          ? {
              positionHeld:
                voe.verificationOfEmployment.responseContent.positionHeld,
              startDate: new Date(
                voe.verificationOfEmployment.responseContent.startDate
              ),
              endDate: voe.verificationOfEmployment.responseContent.endDate
                ? new Date(voe.verificationOfEmployment.responseContent.endDate)
                : null,
              reasonForLeaving:
                voe.verificationOfEmployment.responseContent.reasonForLeaving,
              eligibleForRehire:
                voe.verificationOfEmployment.responseContent
                  .eligibleForRehire || false,
              isDriverTerminated:
                voe.verificationOfEmployment.responseContent.isDriverTerminated,
              isDriverSubjectToFMCSRs:
                voe.verificationOfEmployment.responseContent
                  .isDriverSubjectToFMCSRs,
              isJobDesignatedAsSafetySensitiveFunctionUnderDOT:
                voe.verificationOfEmployment.responseContent
                  .isJobDesignatedAsSafetySensitiveFunctionUnderDOT,
              accidents: voe.verificationOfEmployment.accidents,
            }
          : {}
        : {
            accidents: voe.verificationOfEmployment.accidents,
            positionHeld:
              voe.verificationOfEmployment.requestContent.positionHeld,
            startDate: new Date(
              voe.verificationOfEmployment.requestContent.startDate
            ),
            endDate: voe.verificationOfEmployment.requestContent.endDate
              ? new Date(voe.verificationOfEmployment.requestContent.endDate)
              : null,
            reasonForLeaving:
              voe.verificationOfEmployment.requestContent.reasonForLeaving,
            eligibleForRehire:
              voe.verificationOfEmployment.requestContent.eligibleForRehire ||
              false,
            isDriverTerminated:
              voe.verificationOfEmployment.requestContent.isDriverTerminated,

            isDriverSubjectToFMCSRs:
              voe.verificationOfEmployment.requestContent
                .isDriverSubjectToFMCSRs,
            isJobDesignatedAsSafetySensitiveFunctionUnderDOT:
              voe.verificationOfEmployment.requestContent
                .isJobDesignatedAsSafetySensitiveFunctionUnderDOT,
          }
    )
  }

  const isCompleted =
    voe?.verificationOfEmployment.status === VoEStatus.COMPLETED

  const handleAccidentsUpdate = async ({ accidents }) => {
    form.setFieldValue(`accidents`, accidents)
    return { errors: null }
  }

  const onPreviewDriverConsent = useCallback(() => {
    if (!driverConsentFileUrl) {
      getDriverFileUrl()
    } else {
      setOpenConsent(true)
    }
  }, [driverConsentFileUrl])

  const onConsentDownload = useCallback(() => {
    downloadFile({
      url: driverConsentFileUrl,
      fileName: 'consent.pdf',
    })
  }, [driverConsentFileUrl])

  if (loading) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <Loader />
      </div>
    )
  }
  return (
    <form
      className="flex h-full flex-col gap-6  overflow-auto px-6 pb-10"
      onSubmit={() => handleSubmit()}
    >
      <Metadata title="VoE Response" description="Voe Response page" />
      <VoEDetailsHeader
        showSubmitButton={!isCompleted}
        submitButtonText="Mark as completed"
        loadingSubmit={loadingSubmit}
        driverName={voe.verificationOfEmployment.driverName}
        title="VoE Response"
      />
      <RequestInfoCard voe={voe.verificationOfEmployment} />
      <CompanyInfoCard voe={voe.verificationOfEmployment} />
      <DriverInfoCard voe={voe.verificationOfEmployment} />

      {/* REQUEST CONTENT */}
      <div className="flex flex-col gap-6 rounded-lg border border-doubleNickel-gray-200">
        <div className="flex flex-col gap-6 p-6">
          <div className="text-md font-semibold text-doubleNickel-gray-900">
            Request Content
          </div>
          <div className="flex flex-row gap-8 ">
            <RequestContentColumn
              form={form}
              request={voe.verificationOfEmployment.requestContent}
            />
            <Divider orientation="vertical" />
            <ResponseContentColumn
              editing={false}
              form={form}
              request={voe.verificationOfEmployment.requestContent}
            />
          </div>
          <div className="flex flex-row items-center gap-4 rounded-lg bg-doubleNickel-brand-25 px-4 py-3">
            <IconAlertCircle className="fill-none stroke-doubleNickel-brand-500" />
            <div className="flex-1">
              <QuestionRow
                required
                text="Is this applicant eligible for rehire?"
                value={
                  voe.verificationOfEmployment?.responseContent
                    ?.eligibleForRehire || null
                }
                handleChange={(value) =>
                  form.setFieldValue(`eligibleForRehire`, value === 'true')
                }
                editing={false}
              />
            </div>
          </div>
        </div>
      </div>
      {form.values.accidents && (
        <AccidentsCard
          values={form.values.accidents}
          handleUpdateApplication={handleAccidentsUpdate}
          readOnly={true}
        />
      )}
      <VoEConsentCard
        onPreview={onPreviewDriverConsent}
        loading={loadingPreviewConsent}
      />

      {openConsent && (
        <PDFPreviewModal
          fileName="Driver consent"
          url={driverConsentFileUrl}
          isOpen={openConsent}
          onClose={() => setOpenConsent(null)}
          onBack={() => setOpenConsent(null)}
          onDownload={onConsentDownload}
        />
      )}

      <AuditTrailCard
        auditTrailLogs={voe.verificationOfEmployment.auditTrailLogs}
      />

      {!isCompleted && (
        <div className="flex w-full flex-row justify-end">
          <Button
            text="Mark as completed"
            loading={loadingSubmit}
            type="submit"
          />
        </div>
      )}
    </form>
  )
}

export default VoEResponseDetailPage
